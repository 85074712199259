import axios from "axios";
import qs from 'qs';
import ElementUI from 'element-ui';
import VueCookies from 'vue-cookies'
// 引入状态管理
import store from '../vuex/store';


const baseURL = process.env.VUE_APP_BASE_URL
const timeout = 50000;
const http = axios.create({ baseURL, timeout });

//axias拦截器=>请求
http.interceptors.request.use(
    config => {
        // 请求之前的操作
        
        const url = VueCookies.get("GOLIVE-REDIRECT-FALG");
        if (!!url) {
            config.headers.Authorization = `${url}`;
        }
        return config;
    },
    error => Promise.reject(error),
);


//axias拦截器=>响应
http.interceptors.response.use(
    config => {
        //响应到达浏览器之前做的操作
       
        return config.data;
    },
    error => {
        //失败操作  可以做全局失败处理
        if(error.response.status==500){
            ElementUI.Message.error('未知错误')
        }else if(error.response.status==401){
            ElementUI.Message.error('授权异常')
            // store.commit('logout', 'false')
            // location.reload()
        }else if(error.response.status==403){
            ElementUI.Message.error('禁止访问')
        }else if(error.response.status==400){
            ElementUI.Message.error('请求失败')
        }
        // else{
        //     ElementUI.Message.error('请求失败') 
        //     return Promise.resolve(error.response);
        // }
        
       
    },
);

// 通用请求
function request(options = {}) {
    // Toast.loading({ message: '加载中...', forbidClick: false });
    return http.request({ ...options });
}

export default {
    request,
    get(url, params) {
        return request({ url, method: 'get', params });
    },
    post(url, data) {
        return request({
            url,
            method: 'post',
            data: data,
            headers: {
            'content-Type': 'application/json',
            },
        });
    },
    put(url, data) {
        return request({
            url,
            method: 'put',
            data: data,
            headers: {
            'content-Type': 'application/json',
            },
        });
    },
    patch(url, data) {
        return request({
            url,
            method: 'patch',
            data: data,
            headers: {
            'content-Type': 'application/json',
            },
        });
    },
    delete(url, data) {
        return request({
            url,
            method: 'delete',
            data: data,
            headers: {
            'content-Type': 'application/json',
            },
        });
    },
    export(url, params) {
        return request({
            url,
            method:'get', 
            params: params,
            responseType: "blob",
        });
    },
};
<template>
    <div class="hello">
    </div>
</template>
  
  <script>
  export default {
    name: 'login',
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  </style>
  
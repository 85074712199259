import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
// 登录验证
export default new Vuex.Store({
    state: {
        login_falg: 0,
        redirect_flag: null
    },
    // getters: {
    //     login_falg: this.$cookies.get('GOLIVE-LOGIN-FALG'),
    //     redirect_flag: this.$cookies.get('GOLIVE-REDIRECT-FALG'),
    // },
    mutations: {
        // 登录
        login(state, data) {
            this.$cookies.set('GOLIVE-LOGIN-FALG', 1, "1d");
            this.$cookies.set('GOLIVE-REDIRECT-FALG', data, "1d");
        },
        // 退出
        logout(state) {
            var exp = new Date();
            exp.setTime(exp.getTime() - 1);
            
            this.$cookies.set('GOLIVE-LOGIN-FALG', 0, exp);
            this.$cookies.set('GOLIVE-REDIRECT-FALG', null, exp);
        }
    }
    
})
import { render, staticRenderFns } from "./f10853316.vue?vue&type=template&id=4f163ead&scoped=true&"
import script from "./f10853316.vue?vue&type=script&lang=js&"
export * from "./f10853316.vue?vue&type=script&lang=js&"
import style0 from "./f10853316.vue?vue&type=style&index=0&id=4f163ead&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f163ead",
  null
  
)

export default component.exports
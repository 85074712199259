<!-- 10天缓存登录 -->
<template>
    <div class="demo">
      <div style="background-color: #fff;" class="content">
        
        <div class="back-img">
          <img src="../assets/img/10886435bg.jpg" id="login-w3topimg" style="width: 100%;"/>
        </div>
        <div style="margin-bottom: 12px;padding: 0px 50px;">
          <label class="label-txt">单位类型</label>
          <el-select v-model="type" placeholder="请选择单位类型" class="label-value">
            <el-option
              v-for="item in options1" :key="item.value" :label="item.label"  :value="item.value">
            </el-option>
          </el-select> 
        </div>
        
        <el-form v-show="type<3" :model="ruleForm1" :rules="rules1" ref="ruleForm1" style="padding: 0px 50px 0px 50px;" >
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm1.name"></el-input>
          </el-form-item>
          <el-form-item label="公司" prop="company">
            <el-input v-model="ruleForm1.company"></el-input>
          </el-form-item>
          <el-form-item label="职务" prop="job">
            <el-input v-model="ruleForm1.job"></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="mobile">
            <el-input v-model="ruleForm1.mobile"></el-input>
          </el-form-item>
        </el-form>
        
        <el-form v-show="type==3" :model="ruleForm1" :rules="rules2" ref="ruleForm2" style="padding: 0px 50px 0px 50px;" >
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm1.name"></el-input>
          </el-form-item>
          <el-form-item label="医院" prop="hospital">
            <el-input v-model="ruleForm1.hospital"></el-input>
            <!-- <el-select v-model="ruleForm1.hospital" filterable placeholder="" 
            ref="select"
            @hook:mounted="cancalReadOnly"
            @visible-change="cancalReadOnly"
            class="label-value">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item label="科室" prop="department">
            <el-input v-model="ruleForm1.department"></el-input>
            <!-- <el-select v-model="ruleForm1.department" filterable placeholder=""  class="label-value">
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item label="职务" prop="job">
            <el-input v-model="ruleForm1.job"></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="mobile">
            <el-input v-model="ruleForm1.mobile"></el-input>
          </el-form-item>
        </el-form>
        <div style="padding: 0px 50px 50px 50px;" >
          <p class="check-txt " ><el-checkbox v-model="checked" > </el-checkbox>
            我已知晓并同意以上信息及活动中提交的其他个人信息用于签到、活动管理等活动相关目的。
          </p>
          <el-button  type="primary" round style="width: 100%;" @click="submitForm" :disabled="!checked" :loading="loading">登记</el-button>
        </div>
        
      </div>
    </div>
</template>
  
  <script>
import { formsubmit,logincheck} from '../api/basisMG'

  export default {
    name: 'form1',
    data() {
      let checkPhone = (rule, value, callback) => {
        // let reg = /^1[345789]\d{9}$/
        let reg = /(^1[3|4|5|7|8]\d{9}$)|(^09\d{8}$)/
        if (!reg.test(value)) {
          callback(new Error('请输入11位手机号'))
        } else {
          callback()
        }
      }
      return {
        live_id: '',
        checked: false,
        loading: false,
        type: null,
        options1:[
          {label:'医院',value: 3},
          {label:'非医院',value: 2},
          // {label:'企业',value: 1}
        ],
        ruleForm1: {
          name: '',
          company: '',
          job: '',
          mobile: '',
          hospital: '',
          department: ''
        },
        rules1: {
          name: [
            { required: true, message: '请输入姓名', trigger: 'blur' }
          ],
          company: [
            { required: true, message: '请输入公司名称', trigger: 'blur' }
          ],
          job: [
            { required: true, message: '请输入职务', trigger: 'blur' }
          ],
          mobile: [
            { required: true, message: '请输入联系方式', trigger: 'blur' },
            { validator: checkPhone, message: '请输入正确的手机号', trigger: 'blur'},
          ],
        },
        // ruleForm2: {
        //   name: '',
        //   hospital: '',
        //   department: '',
        //   job: '',
        //   mobile: ''
        // },
        rules2: {
          name: [
            { required: true, message: '请输入姓名', trigger: 'blur' }
          ],
          hospital: [
            { required: true, message: '请输入医院', trigger: 'blur' }
          ],
          job: [
            { required: true, message: '请输入职务', trigger: 'blur' }
          ],
          mobile: [
            { required: true, message: '请输入联系方式', trigger: 'blur' },
            { validator: checkPhone, message: '请输入正确的手机号', trigger: 'blur'},
          ],
          department: [
            { required: true, message: '请输入科室', trigger: 'blur' },
          ],
        },
        options2: [],
        options3: [],
        assembly:[]
      };
    },
    methods: {
      submitForm() {
        this.loading = true
        if(this.type!=3 && this.type!=2){
          this.$message.warning('请选择单位类型')
          return
        }
        let formName = 'ruleForm1'
        if(this.type==3){
          formName = 'ruleForm2'
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            
            const assembly = [
                { "value":this.ruleForm1.name,"name":'姓名'},
                { "value":this.ruleForm1.company,"name":'公司'},
                { "value":this.ruleForm1.hospital,"name":'医院'},
                { "value":this.ruleForm1.department,"name":'科室'},
                { "value":this.ruleForm1.job,"name":'职务'},
                { "value":this.ruleForm1.mobile,"name":'联系方式'}
            ]
            const param = {
              "uid": this.$md5(this.ruleForm1.mobile),
              "id":this.live_id,
              "username": this.ruleForm1.name,
              "data": assembly
            }
            formsubmit(param).then(res=>{
                this.loading = false
                if(res.code=="0"){
                  this.$cookies.set('GOLIVE-LOGIN-FALG2', 3, "1d","/"+this.live_id); //1天缓存
                  this.$cookies.set('GOLIVE-REDIRECT-FALG2', res.data, "10d" ,"/"+this.live_id); //10天缓存
                  window.open(res.data,"_self");
                }else{
                  this.$message.error(res.message)
                }
            }).catch(err => {
              this.loading = false
            })
		
          } else{
            this.loading = false
          } 
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      cancalReadOnly(value) {
       this.$nextTick(() => {
              if (!value) {
              const { select } = this.$refs;
              const input = select.$el.querySelector(".el-input__inner");
              input.removeAttribute("readonly");
              // this.$refs.select.blur();  根据tip自行判断是否添加
              }
          });
      },
    },
    mounted(){
      let data =  require('../assets/json/hospital.json')
      this.options2 = data.yiyuan
      this.options3 = data.keshi

      let pathname = window.location.pathname;
      this.live_id = pathname.substring(pathname.lastIndexOf('/')+1,pathname.length);
      // console.log(live_id)
      const login_falg = this.$cookies.get('GOLIVE-LOGIN-FALG2'); //1天缓存
      var url = this.$cookies.get('GOLIVE-REDIRECT-FALG2');  //10天缓存
      if(login_falg==3 && url){
        url = url.substr(0,url.indexOf('watch/')+6)+this.live_id+url.substr(url.indexOf('?'),url.length)
        window.open(url,"_self");

      }else if(!login_falg && url){
        logincheck().then(res=>{
          console.log(res);
          if(res.code=="0"){
            window.open(res.data,"_self");
          }
        })
      }

    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .demo{
      background-color: #f5f5f5;
      display: flex;
      justify-content: center;
    }
    .back-img{
       /* background-image: url(../assets/img/10842559bg.jpg);
      background-position: center center; */
      /* 背景图不平铺 */
      /* background-repeat: no-repeat; */
      /* 让背景图基于容器大小伸缩 */
      /* background-size: cover; */
    }
    .label-txt{
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #606266;
      line-height: 40px;
      padding: 0 12px 0 10px;
      box-sizing: border-box;
      /* width: 100px; */
    }
    .label-value{
      width: 100%;
      line-height: 40px;
      position: relative;
      font-size: 14px;
      /* margin-left: 8px; */
    }
    .check-txt{
      line-height: 20px;
      color: #8e8b8b;
      font-size: 10px;
      visibility: visible;
      vertical-align: baseline;
    }
    @media (min-width: 720px) {
      .demo .content{
        width: 40%;
      }
    }
    @media (max-width: 720px) {

    }
  </style>
  
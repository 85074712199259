<!-- 1天缓存登录  ， ， ，班级-->
<template>
    <div class="demo">
      <div style="background-color: #fff;" class="content">
        
        <div class="back-img">
          <img src="../assets/img/10860737bg.png" id="login-w3topimg" style="width: 100%;"/>
        </div>
        
        <el-form :model="ruleForm1" :rules="rules1" ref="ruleForm1" style="padding: 0px 50px 0px 50px;" >
          <el-form-item label="学校" prop="school">
            <el-input v-model="ruleForm1.school"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm1.name"></el-input>
          </el-form-item>
          <el-form-item label="学号" prop="id">
            <el-input v-model="ruleForm1.id"></el-input>
          </el-form-item>
          <el-form-item label="学院" prop="college">
            <el-input v-model="ruleForm1.college"></el-input>
          </el-form-item>
          <el-form-item label="专业" prop="professional">
            <el-input v-model="ruleForm1.professional"></el-input>
          </el-form-item>
          <el-form-item label="班级" prop="class">
            <el-input v-model="ruleForm1.class"></el-input>
          </el-form-item>
        </el-form>
        
        <div style="padding: 0px 50px 50px 50px;" >
          <p class="check-txt " ><el-checkbox v-model="checked" > </el-checkbox>
            我已知晓并同意以上信息及活动中提交的其他个人信息用于签到、活动管理等活动相关目的。
          </p>
          <el-button  type="primary" round style="width: 100%;" @click="submitForm" :disabled="!checked" :loading="loading">登记</el-button>
        </div>
        
      </div>
    </div>
</template>
  
  <script>
import { formsubmit,logincheck} from '../api/basisMG'

  export default {
    name: 'form3',
    data() {
      return {
        live_id: '',
        checked: false,
        loading: false,
        ruleForm1: {
          school: '',
          name: '',
          id: '',
          college: '',
          professional: '',
          class: ''
        },
        rules1: {
          school: [
            { required: true, message: '请输入学校', trigger: 'blur' }
          ],
          name: [
            { required: true, message: '请输入姓名', trigger: 'blur' }
          ],
          id: [
            { required: true, message: '请输入学号', trigger: 'blur' }
          ],
          college: [
            { required: true, message: '请输入学院', trigger: 'blur' },
          ],
          professional: [
            { required: true, message: '请输入专业', trigger: 'blur' },
          ],
          class: [
            { required: true, message: '请输入班级', trigger: 'blur' },
          ],
        },
        options2: [],
        options3: [],
        assembly:[]
      };
    },
    methods: {
      submitForm() {
        this.loading = true
        let formName = 'ruleForm1'
        this.$refs[formName].validate((valid) => {
          if (valid) {
            
            const assembly = [
                { "value":this.ruleForm1.school,"name":'学校'},
                { "value":this.ruleForm1.name,"name":'姓名'},
                { "value":this.ruleForm1.id,"name":'学号'},
                { "value":this.ruleForm1.college,"name":'学院'},
                { "value":this.ruleForm1.professional,"name":'专业'},
                { "value":this.ruleForm1.class,"name":'班级'}
            ]
            const param = {
              "uid": this.$md5(this.ruleForm1.id),
              "id":this.live_id,
              "username": this.ruleForm1.name,
              "data": assembly
            }
            formsubmit(param).then(res=>{
                this.loading = false
                if(res.code=="0"){
                  this.$cookies.set('GOLIVE-LOGIN-FALG1', 1, "1d","/"+this.live_id); //1天缓存
                  this.$cookies.set('GOLIVE-REDIRECT-FALG1', res.data, "1d" ,"/"+this.live_id); //1天缓存
                  window.open(res.data,"_self");
                }else{
                  this.$message.error(res.message)
                }
            }).catch(err => {
              this.loading = false
            })

            
          }else{
            this.loading = false
          } 
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      cancalReadOnly(value) {
       this.$nextTick(() => {
              if (!value) {
              const { select } = this.$refs;
              const input = select.$el.querySelector(".el-input__inner");
              input.removeAttribute("readonly");
              // this.$refs.select.blur();  根据tip自行判断是否添加
              }
          });
      },
    },
    mounted(){
      let pathname = window.location.pathname;
      this.live_id = pathname.substring(pathname.lastIndexOf('/')+1,pathname.length);
      // console.log(live_id)
      const login_falg = this.$cookies.get('GOLIVE-LOGIN-FALG1'); //1天缓存
      var url = this.$cookies.get('GOLIVE-REDIRECT-FALG1');  //1天缓存
      // console.log(login_falg+"----"+url)
      if(login_falg==1 && url){
        url = url.substr(0,url.indexOf('watch/')+6)+this.live_id+url.substr(url.indexOf('?'),url.length)
        window.open(url,"_self");

      }

    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .demo{
      background-color: #f5f5f5;
      display: flex;
      justify-content: center;
    }
    .back-img{
       /* background-image: url(../assets/img/10842559bg.jpg);
      background-position: center center; */
      /* 背景图不平铺 */
      /* background-repeat: no-repeat; */
      /* 让背景图基于容器大小伸缩 */
      /* background-size: cover; */
    }
    .label-txt{
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #606266;
      line-height: 40px;
      padding: 0 12px 0 10px;
      box-sizing: border-box;
      /* width: 100px; */
    }
    .label-value{
      width: 100%;
      line-height: 40px;
      position: relative;
      font-size: 14px;
      /* margin-left: 8px; */
    }
    .check-txt{
      line-height: 20px;
      color: #8e8b8b;
      font-size: 10px;
      visibility: visible;
      vertical-align: baseline;
    }
    @media (min-width: 720px) {
      .demo .content{
        width: 40%;
      }
    }
    @media (max-width: 720px) {

    }
  </style>
  
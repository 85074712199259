// 导入组件
import Vue from 'vue';
import Router from 'vue-router';
// 登录
import login from '@/view/login';
// 登录 10天缓存登录页面
import form1 from '@/view/f10842559';
import form2 from '@/view/f10853316';
import form4 from '@/view/f10886435';
// 登录 1天缓存登录页面
import form3 from '@/view/f10860737';

// 启用路由
Vue.use(Router);

// 导出路由 
export default new Router({
    mode: "history",
    routes: [{
        path: '/',
        name: '',
        component: login,
        hidden: true,
        meta: {
            requireAuth: false
        }
    }, {
        path: '/login',
        name: '登录',
        component: login,
        hidden: true,
        meta: {
            requireAuth: false
        }
    },{
        path: '/10842559',
        name: '登录',
        component: form1,
        hidden: true,
        meta: {
            requireAuth: false,
            title: '“孕育美丽 贝加关爱”—女性健康管理论坛暨维生素D上市会'
        }
    },{
        path: '/10853316',
        name: '登录',
        component: form2,
        hidden: true,
        meta: {
            requireAuth: false,
            title: '数分析 智检验'
        }
    },{
        path: '/10860737',
        name: '登录',
        component: form3,
        hidden: true,
        meta: {
            requireAuth: false,
            title: '中华智慧V模式，创意创新六步法'
        }
    },{
        path: '/10886435',
        name: '登录',
        component: form4,
        hidden: true,
        meta: {
            requireAuth: false,
            title: '贝加守护，智检未来'
        }
    },  ]
})
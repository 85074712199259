import Vue from 'vue'
import App from './App.vue'
// 引入element UI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 使用element UI
Vue.use(ElementUI);

//引入cookie
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

// 引入路由
import router from './router';
// 引入状态管理
import store from './vuex/store';

import axios from 'axios';
Vue.prototype.$axios = axios;

Vue.config.productionTip = false

// 过滤器
import * as custom from './utils/util'

Object.keys(custom).forEach(key => {
    Vue.filter(key, custom[key])
})


router.beforeEach((to, from, next) => {
  if (to.meta.title) { //拦截后设置标题
    document.title = to.meta.title
    let s = document.createElement('meta')
    s.setAttribute('name', 'description')
    s.setAttribute('content', to.meta.title)
    document.head.appendChild(s)
  }
  next()
})
// 路由拦截器
// router.beforeEach((to, from, next) => {
//   if (to.matched.length != 0) {
//     const login_falg = this.$cookies.get('GOLIVE-LOGIN-FALG');
//     if(login_falg==1){
//       var url = this.$cookie.get('GOLIVE-REDIRECT-FALG');
//       url = url.substr(0,url.indexOf('watch/')+6)+id+url.substr(url.indexOf('?'),url.length)
//       window.open(url,"_self");
//     }
//   } else {
//       next({
//           path: '/login',
//           query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
//       })
//   }
// })

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store, //使用store vuex状态管理
  components: { App },
  template: '<App/>',
  data: {
      // 空的实例放到根组件下，所有的子组件都能调用
      Bus: new Vue()
  }

})

// new Vue({
//   render: h => h(App),
// }).$mount('#app')
